import React from 'react';
import logo from './logo512.png';
import flag_fr from './flag_fr.png';
import google_play_badge from './google-play-badge.png';

import { 
  BrowserRouter as Router, 
  Route,
  useRouteMatch,
  useParams,
  Switch
} from "react-router-dom";
import './App.css';

class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = { showButton: true };
  }

  componentDidMount() {
    if(typeof navigator.getInstalledRelatedApps === "function") {
      navigator.getInstalledRelatedApps().then((relatedApps) => {
        this.setState({ showButton: (relatedApps.length === 0) });
      });
    }
  }

  render() {
    return (
      <Router>
        <div className="App">
          <header className="App-header">
            <img src={logo} className="App-logo" alt="logo" />
            <p><img src={flag_fr} className="flag" alt="flag FR" /> The Soundbox - Gabistoufly</p>

            <Switch>
              <Route exact path="/">
                <p>🎉🪕⏰☎️😱🔔🐐🤙🚨🎉</p>
              </Route>
              <Route path="/:soundId">
                <Deeplink />
              </Route>
            </Switch>

            { this.state.showButton ? <DownloadButton /> : null }

          </header>
        </div>
      </Router>
    );
  }
}

export default App;

function DownloadButton() {
  return (
    <a href="https://play.google.com/store/apps/details?id=fr.gabistoufly.the_soundbox">
      <img src={google_play_badge} className="Download-button" alt="Download app" />
    </a>
  );
}

function Deeplink() {
  let match = useRouteMatch();

  let contentUrl = "gbstfly://thesoundbox.gabistoufly.fr" + match.url;

  return (
    <Router>
      <Switch>
        <Route path={match.path + "/:name"}>
          <DeeplinkVue />
        </Route>
      </Switch>

      <a href={contentUrl} className="App-link">
        🔊 Jouer dans l'appli 🔊
      </a>
    </Router>
  );
}

function DeeplinkVue(){
  let { name } = useParams();

  return (
    <p className="sound-name">{name}</p>
  );
}